import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPageV1 from "./LandingPageV1";
import LandingPageV2 from "./LandingPageV2";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPageV1 />} />
                <Route path="/v2" element={<LandingPageV2 />} />
            </Routes>
        </Router>
    );
}

export default App;
