import React, { useState } from 'react';
import './App.css';
import headerImage from './assets/header.png';
import playButtonIcon from './assets/download.svg';
import videoFile from './assets/logo_intro_v4_A.mp4';


function App() {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const openVideo = () => {
        setIsVideoOpen(true);
    };

    const closeVideo = () => {
        setIsVideoOpen(false);
    };

    return (
        <div className="App">
            {/* Background container */}
            <div className="content">
                {/* Header */}
                <img className="header-image" src={headerImage} alt="Header" />

                {/* Play Button */}
                <img
                    className="play-button"
                    src={playButtonIcon}
                    alt="Play"
                    onClick={openVideo}
                />

                {/* Footer Text */}
                <div className="footer-text">A new paradigm of content financing</div>
            </div>

            {/* Video Overlay */}
            {isVideoOpen && (
                <div className="video-container" onClick={closeVideo}>
                    <video
                        className="video-player"
                        src={videoFile}
                        controls
                        autoPlay
                    />

                </div>
            )}
        </div>
    );
}

export default App;
