import React, { useEffect, useState, useRef } from 'react';
import videoFile from './assets/logo_intro_v4_A.mp4';
import headerImage from './assets/header.png';
import playButtonIcon from './assets/download.svg';

function AppV2() {
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [isFullVideoOpen, setIsFullVideoOpen] = useState(true);
    const [showMainContent, setShowMainContent] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) {
            console.error('Video element not found');
            return;
        }

        const handleVideoEnd = () => {
            setIsFullVideoOpen(false);
            setShowMainContent(true);
        };

        // Attach event listeners
        videoElement.addEventListener('ended', handleVideoEnd);

        return () => {
            // Cleanup event listeners
            videoElement.removeEventListener('ended', handleVideoEnd);
        };
    }, []);

    const openVideo = () => {
        setIsVideoOpen(true);
    };

    const closeVideo = () => {
        setIsVideoOpen(false);
    };

    return (
        <div className="App">
            {isFullVideoOpen && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'black',
                    }}
                >
                    <video
                        ref={videoRef}
                        id="fullscreen-video"
                        src={videoFile}
                        autoPlay
                        muted
                        controls={true}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            zIndex: 1000,
                        }}
                    />
                </div>
            )}

            {showMainContent && (
                <div className="content">
                    <img className="header-image" src={headerImage} alt="Header" />
                    <img
                        className="play-button"
                        src={playButtonIcon}
                        alt="Play"
                        onClick={openVideo}
                    />
                    <div className="footer-text">A new paradigm of content financing</div>
                </div>
            )}

            {isVideoOpen && (
                <div className="video-container" onClick={closeVideo}>
                    <video
                        className="video-player"
                        src={videoFile}
                        controls
                        autoPlay
                    />

                </div>
            )}
        </div>
    );
}

export default AppV2;
